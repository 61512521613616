import { TGenusTile } from 'components/aftercare/aftercare-tile/types';
import { TSanityBlock } from 'components/cms-blocks/render-blocks/types';
import { FooterLinkColumnProps } from 'components/common/footer';
import { IAlerts } from 'components/common/header-alert/types';
import {
  NavLink,
  NavRoot,
} from 'components/common/header/components/mega-nav/types';
import { ICustomMetaTagsSanity } from 'components/common/meta-tags/types';
import { ImagesProps } from 'components/common/press-banner/types';
import { IValuesImage } from 'components/common/values/types';
import { PDPCollection } from 'components/layouts/product/types';
import { TPublicPartner } from 'contexts/settings/types';
import { ICategoryPreview } from 'types/category';
import { IPartnerPreview } from 'types/partner';

export interface SanityImageAsset {
  _type: 'image';
  _key?: string;
  asset: {
    _ref: string;
    _type: 'reference';
    altText?: string;
  };
}

export interface SanityArticlePreview {
  _id: string;
  title: string;
  _createdAt: string;
  slug: {
    _type: 'slug';
    current: string;
  };
  category?: {
    name?: string;
  };
  image?: SanityImageAsset;
  headerBlocks?: TSanityBlock[] | null;
  footerBlocks?: TSanityBlock[] | null;
  readingTime?: string;
  isVideo?: boolean;
}

export interface SanityArticle extends SanityArticlePreview {
  _updatedAt: string;
  lede: string;
  subtitle: string;
  meta?: ICustomMetaTagsSanity;
  contentTags: IContentTag[];
  alignment?: 'left' | 'center' | 'right';
  titleSize?: 'large' | 'medium';
}

export interface IContentTag {
  _id: string;
  _createdAt: string;
  _type: 'contentTag';
  name: string;
  slug: {
    current: string;
  };
}

export interface IContentBlock {
  _key: string;
  _type: 'Content Tag';
  contentTag: IContentTag;
  offset: number;
  content: IInjectableContent[];
  position: number;
}

export interface IInjectableContent {
  article: SanityArticle | null;
  genus: TGenusTile | null;
  collection: PDPCollection | null;
  category: ICategoryPreview | null;
  partner: IPartnerPreview | null;
  edit: IEditPreview | null;
  tag: string;
  offset: number;
  position: number;
}

export interface IEditPreview {
  _id: string;
  name: string;
  subTitle: string;
  slug: {
    current: string;
  };
  banner?: Sanity.ImageAsset;
}

export interface IContentPreview {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  image: SanityImageAsset;
  preview: {
    title: string;
    theme: string;
    cta: string;
    image?: SanityImageAsset;
  };
}

export interface BreakpointValues {
  [key: string]: number;
}

interface IRawProductAttribute {
  __typename?: 'RawProductAttribute';
  name: string;
  value: any;
}

export interface IObjectWithCustomAttributes {
  attributesRaw: IRawProductAttribute[];
}

export type TPopularContent = {
  articles: any[];
  articlesTitle: string;
  products: string[];
  productsTitle: string;
};

export type TPopularSearches = {
  title: string;
  items: NavLink[];
};
export interface ISearch {
  popularContent: TPopularContent;
  popularSearches: TPopularSearches;
}

export interface ISiteGlobals {
  header?: {
    alertTheme: string;
    alertBackgroundTransparency?: number;
    alerts?: IAlerts[];
    menu: NavRoot;
    secondaryAlertsTheme: string;
    secondaryAlertsBackgroundTransparency?: number;
    secondaryAlerts?: IAlerts[];
  };
  footer?: ISiteGlobalsFooter;
  pressBanner?: {
    images?: ImagesProps[];
    display?: any;
  };
  valuesBanner?: {
    images?: IValuesImage[];
  };
  search: ISearch;
}

export interface ISiteGlobalsFooter {
  linkColumns?: FooterLinkColumnProps[];
  newsletterColumn?: {
    title?: string;
    body?: string;
  };
  rightColumn?: ISiteGlobalsFooterRightColumn;
}

export interface ISiteGlobalsFooterRightColumn {
  image?: Sproutl.CloudinaryImageAsset;
  body?: string;
  cta?: string;
  ctaLink?: string;
  altText?: string;
}

export interface IResponsivePaddingObj {
  breakpoint: number;
  paddingBottomPercentage: string;
}

export interface IDelayedRenderProps {
  isOpen: boolean;
  transitionDuration: number;
}

export interface IPublicPartnerSettings {
  _id: string;
  partners?: TPublicPartner[];
}

export enum AddingState {
  idle = 'Add to Basket',
  adding = 'Adding to Basket',
  added = 'Added to Basket',
}

export interface IAttributeDefinition {
  _id: string;
  _createdAt: string;
  _updatedAt: string;
  _type: 'attributeDefinition';
  title: string;
  shortTitle?: string;
  slug: {
    _type: 'slug';
    current: string;
  };
  algoliaId?: string;
  hierarchicalAttributes?: string;
  filterType: string;
  order: number;
  pdpLayout: string[];
  prefix?: string;
  suffix?: string;
  tooltip?: string;
  values?: IAttributeDefinitionValue[] | null;
}

export interface IAttributeDefinitionValue {
  _id: string;
  _type: 'value';
  value?: string;
  tooltip?: string;
  icon: SanityImageAsset;
  url?: string;
}

export interface IAttributeDefinitionWithValue extends IAttributeDefinition {
  value: any;
}

export interface IKit {
  _id: string;
  title: string;
  pimId: string;
  groupIds: string[];
  description?: Sanity.PortableTextBlock[];
  cta?: {
    title?: string;
    url?: string;
  };
}
