import styled from 'styled-components';

import { Heading5 } from 'components/common/text';
import theme from 'utils/theme';

interface SummaryProps {
  size: 'normal' | 'small' | 'custom';
}

export const Summary = styled.summary<SummaryProps>`
  display: flex;
  align-items: center;
  padding: ${({ size }) =>
    size === 'small' ? 'var(--space-s-l) 0' : 'var(--space-s-l) 0'};
  border-top: 1px solid ${theme.colors.iconUnderlay};
`;

interface TitleRowProps {
  titleLayout: 'inline' | 'flex';
}

export const TitleRow = styled.div<TitleRowProps>`
  flex: 1;
  display: ${(props) => props.titleLayout};
  align-items: baseline;
  flex-wrap: wrap;
  align-self: center;

  ${Heading5} {
    margin-right: var(--space-m);
  }

  > span {
    display: block;
    margin-right: var(--space-m);
    line-height: 1.5;
  }
`;

export const SummaryRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  padding-bottom: var(--space-l-xl);
`;

export const Marker = styled.div`
  height: var(--space-l);
`;

export const Details = styled.details`
  &[open] {
    ${Marker} {
      transform: rotate(180deg);
    }
  }

  &:first-child {
    ${Summary} {
      border-top-width: 0;
    }
  }
`;
