import { FC } from 'react';

import { VARIANT_PROPS } from '../constants';

import * as St from './styles';

interface IProps {
  variantInfo: {
    [key: string]: any[];
  } | null;
}

const Variants: FC<IProps> = ({ variantInfo }) => {
  if (!variantInfo) {
    return null;
  }

  return (
    <>
      {Object.entries(variantInfo).map(([key, value]) => {
        if (value.length < 1) {
          return null;
        }

        const hasMultipleVariants = value.length > 1;

        if (!hasMultipleVariants) {
          return null;
        }

        const lowerBound = value[0].toString().split('-')[0].trim();
        const upperBoundRaw = value[value.length - 1].toString().split('-');
        const upperBound = upperBoundRaw[upperBoundRaw.length - 1].trim();

        return (
          <St.Wrapper key={key}>
            {VARIANT_PROPS[key].prefix}
            {lowerBound} - {upperBound} {VARIANT_PROPS[key].unit}{' '}
            {VARIANT_PROPS[key].suffix}
          </St.Wrapper>
        );
      })}
    </>
  );
};

export default Variants;
