import styled, { css } from 'styled-components';

import Button from 'components/common/button';
import ResponsiveBox from 'components/common/responsive-box';
import { desktop, mobile } from 'utils/media';
import theme from 'utils/theme';

export const TileWrapper = styled.div<{
  enableQuickBuy?: boolean;
}>`
  ${({ enableQuickBuy }) =>
    enableQuickBuy &&
    css`
      &:hover {
        ${TileContentWrapper} *:not(${QuickbuyButton}) {
          ${desktop} {
            opacity: 0;
          }
        }

        ${QuickbuyButton} {
          ${desktop} {
            display: block;
          }
        }
      }
    `}
`;

export const TileContentWrapper = styled.div`
  position: relative;
  margin-top: var(--space-m);
`;

export const LinkWrapper = styled.a`
  display: block;
  color: ${theme.colors.black};
`;

export const ImageWrapper = styled(ResponsiveBox)`
  background-color: ${theme.colors.white};
`;

export const Price = styled.p`
  margin: var(--space-2xs) 0 0;
  color: ${theme.colors.darkGreen};
  font-weight: ${theme.weights.bold};
`;

export const OutOfStock = styled.p`
  border-radius: var(--space-2xs);
  font-weight: ${theme.weights.bold};
  background: ${theme.colors.lighterGrey};
  padding: var(--space-4xs) var(--space-2xs);
  display: inline-block;
  margin: var(--space-2xs) 0 0;
  color: ${theme.colors.black};
`;

export const Title = styled.h3`
  margin: 0;
  font-family: ${theme.fonts.body};
  font-weight: ${theme.weights.bold};
  font-size: var(--step-0);
`;

export const Subtitle = styled.p`
  margin: var(--space-3xs) 0 0;
  color: ${theme.colors.darkGrey};
`;

export const SelectableWrapper = styled.button`
  position: relative;
`;

export const AddToBasketSuccess = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.irisPurple};
  font-family: ${theme.fonts.heading};
  padding: var(--space-2xs-xs) 0; // Button matching
  border: 2px solid transparent; // Button matching

  svg {
    flex-shrink: 0;
    width: 1.125em;
    height: 1.125em;
    margin-right: var(--space-xs);
    transform: translateY(-15%); // Optical adjustment
  }

  ${mobile} {
    font-size: var(--step--1);
  }
`;

export const QuickbuyButton = styled(Button)`
  margin-bottom: var(--space-xs);
  z-index: 1;

  ${desktop} {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }
`;

interface TickBulletWrapperProps {
  selectableBackground: 'offWhite' | 'white';
  selected: boolean;
}

export const TickBulletWrapper = styled.span<TickBulletWrapperProps>`
  display: flex;
  position: absolute;
  top: -2px;
  left: -2px;
  background: ${({ selectableBackground }) =>
    theme.colors[selectableBackground]};
  border: 2px solid
    ${({ selectableBackground }) => theme.colors[selectableBackground]};
  border-radius: 100%;
`;
