import styled from 'styled-components';

import Container from 'components/common/container';
import { Heading6 } from 'components/common/text';
import theme from 'utils/theme';

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: ${theme.zIndexes.cookies};
  background-color: ${theme.colors.white};
  box-shadow: 0px -5px var(--space-2xs) rgba(0, 0, 0, 0.1);
  height: ${theme.sizes.cookies.barTight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Heading6)`
  color: ${theme.colors.purple};
  margin: 0px;
`;

export const Text = styled.p`
  margin: 0;

  a {
    text-decoration: underline;
  }
`;

export const StyledContainer = styled(Container)`
  padding: var(--space-m);
  display: flex;
  justify-content: space-between;
  width: 900px;
`;

export const CtaContainer = styled.div`
  align-self: center;
`;

export const CloseButton = styled.button`
  padding: 0px;
  border: 0px;
  margin: 0px;
  width: var(--space-2xl);
  height: var(--space-2xl);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
  border-radius: 100%;
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;
`;

export const cookieOffset = (
  cookieAccepted: boolean,
  defaultOffset: string | number = '70px',
) => {
  return cookieAccepted ? defaultOffset : `${theme.sizes.cookies.bar}px`;
};
