export function sortItems(a: string, b: string) {
  const aVal = parseFloat(a);
  const bVal = parseFloat(b);

  if (aVal && bVal) {
    return aVal - bVal;
  }

  return a.toUpperCase() < b.toUpperCase() ? -1 : 1;
}
