export const VARIANT_PROPS: {
  [key: string]: {
    label: string;
    prefix: string;
    suffix: string;
    unit: string;
  };
} = {
  heightCurrent: {
    label: 'Current height',
    prefix: '',
    suffix: 'tall',
    unit: 'cm',
  },
  diameterTop: {
    label: 'Diameter',
    prefix: '',
    suffix: 'in diameter',
    unit: 'cm',
  },
  height: {
    label: 'Height',
    prefix: '',
    suffix: 'tall',
    unit: 'cm',
  },
  length: {
    label: 'Length',
    prefix: '',
    suffix: 'in length',
    unit: 'cm',
  },
  volume: {
    label: 'Volume',
    prefix: '',
    suffix: 'in volume',
    unit: 'l',
  },
  width: {
    label: 'Width',
    prefix: '',
    suffix: 'wide',
    unit: 'cm',
  },
};
