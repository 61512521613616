import { debounce, throttle } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { Chevron } from 'components/common/icons';
import { mobile, tablet } from 'utils/media';

import {
  ArrowButton,
  CarouselRowContainer,
  CarouselItem,
  CarouselItemCaption,
  CarouselRowInner,
  ThumbnailLink,
  ThumbnailsGrid,
  ThumbnailContent,
  Dot,
  ArrowWrapper,
  thumbnailWidth,
  DotsGrid,
} from './styles';
import { CarouselRowProps } from './types';

export { CarouselItem, CarouselItemCaption };

const CAROUSEL_DESKTOP_GUTTER = 40;

export function CarouselRow({
  arrowLayout,
  className,
  inline,
  snap,
  children,
  thumbnails = [],
  thumbnailBreakpoint = tablet,
  dotBreakpoint = mobile,
  scrollBarReveal = false,
  hideScrollbar = false,
  removeScrollbarGap = false,
  childCount = 0,
  onSlideChange,
}: CarouselRowProps) {
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(0);
  const [
    thumbnailTrackAutoScrollDisabled,
    setThumbnailTrackAutoScrollDisabled,
  ] = useState<boolean>(false);

  const [availableArrows, setAvailableArrows] = useState({
    left: false,
    right: false,
  });

  const slidesTrackRef = useRef<HTMLDivElement>(null);
  const thumbnailsTrackRef = useRef<HTMLDivElement>(null);
  const activeThumbnailRef = useRef<HTMLDivElement>();
  const { ref: inViewRef, inView: activeThumbnailVisible } = useInView({
    threshold: 1,
  });

  const setActiveThumbnailRefs = useCallback(
    (node) => {
      activeThumbnailRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const arrowJump = useCallback((direction: 'left' | 'right') => {
    if (slidesTrackRef.current) {
      const width = slidesTrackRef.current.offsetWidth;

      slidesTrackRef.current.scrollBy({
        left: direction === 'right' ? width : width - width * 2,
        behavior: 'smooth',
      });
    }
  }, []);

  const thumbnailJump = useCallback((index: number) => {
    if (slidesTrackRef.current) {
      const width = slidesTrackRef.current.offsetWidth;

      slidesTrackRef.current.scrollTo({
        left: index * width,
        behavior: 'smooth',
      });
    }
  }, []);

  const determineAvailableArrows = () => {
    if (!slidesTrackRef.current) return;

    setAvailableArrows({
      left: slidesTrackRef.current.scrollLeft > CAROUSEL_DESKTOP_GUTTER,
      right:
        slidesTrackRef.current.scrollLeft <
        slidesTrackRef.current.scrollWidth -
          slidesTrackRef.current.offsetWidth -
          CAROUSEL_DESKTOP_GUTTER,
    });
  };

  const handleTrackScroll = throttle(() => {
    setThumbnailTrackAutoScrollDisabled(false);

    if (slidesTrackRef.current) {
      const scrollPosition =
        slidesTrackRef.current.scrollLeft + slidesTrackRef.current.offsetWidth;
      let newActiveItem = Math.round(
        slidesTrackRef.current.scrollLeft / slidesTrackRef.current.offsetWidth,
      );

      if (scrollPosition === slidesTrackRef.current.scrollWidth && childCount) {
        newActiveItem = childCount - 1;
      }

      if (newActiveItem !== activeItemIndex) {
        setActiveItemIndex(newActiveItem);
        onSlideChange && onSlideChange(newActiveItem);
      }
    }

    determineAvailableArrows();
  }, 100);

  const scrollThumbnailsTrack = debounce(
    (left: number) => {
      thumbnailsTrackRef?.current?.scrollTo({
        left: left,
        behavior: 'smooth',
      });
    },
    1000,
    {
      trailing: false,
      leading: true,
    },
  );

  useEffect(() => {
    if (thumbnails.length) {
      if (
        !activeThumbnailVisible &&
        thumbnailsTrackRef.current &&
        activeThumbnailRef.current &&
        !thumbnailTrackAutoScrollDisabled
      ) {
        const { clientWidth, scrollLeft } = thumbnailsTrackRef.current;

        const thumbnailsTrackRelWidth = clientWidth + scrollLeft;

        const { offsetLeft, offsetWidth } = activeThumbnailRef.current;

        const visiblePartWidth = thumbnailsTrackRelWidth - offsetLeft;
        const obscuredPartWidth = offsetWidth - visiblePartWidth;

        scrollThumbnailsTrack(scrollLeft + obscuredPartWidth + thumbnailWidth);
      }
    }
  }, [
    activeItemIndex,
    activeThumbnailVisible,
    scrollThumbnailsTrack,
    thumbnailTrackAutoScrollDisabled,
    thumbnails.length,
  ]);

  useEffect(() => {
    determineAvailableArrows();
  }, []);

  const hasArrows = availableArrows.left || availableArrows.right;

  return (
    <>
      {arrowLayout === 'top' && hasArrows && (
        <ArrowWrapper>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        </ArrowWrapper>
      )}

      <CarouselRowContainer className={className}>
        {arrowLayout && arrowLayout !== 'top' && hasArrows && (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
        )}

        <CarouselRowInner
          onScroll={arrowLayout ? handleTrackScroll : undefined}
          ref={slidesTrackRef}
          inline={inline}
          snap={snap}
          scrollBarReveal={scrollBarReveal}
          hideScrollbar={hideScrollbar}
          removeScrollbarGap={removeScrollbarGap}
        >
          {children}
        </CarouselRowInner>

        {arrowLayout && arrowLayout !== 'top' && hasArrows && (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        )}
      </CarouselRowContainer>

      {thumbnails && !!thumbnails.length && (
        <ThumbnailsGrid
          ref={thumbnailsTrackRef}
          onScroll={() => setThumbnailTrackAutoScrollDisabled(true)}
          breakpoint={thumbnailBreakpoint}
        >
          {thumbnails.map((thumbnail, index) => (
            <ThumbnailLink
              onClick={() => thumbnailJump(index)}
              type="button"
              key={index}
              aria-label={`Go to image ${index + 1}`}
            >
              <ThumbnailContent
                active={activeItemIndex === index}
                ref={activeItemIndex === index ? setActiveThumbnailRefs : null}
              >
                {thumbnail}
              </ThumbnailContent>
            </ThumbnailLink>
          ))}
        </ThumbnailsGrid>
      )}

      {!!childCount && childCount > 1 && (
        <DotsGrid breakpoint={dotBreakpoint}>
          {Array.from({ length: childCount }, (_, i) => i).map((index) => (
            <ThumbnailLink
              onClick={() => thumbnailJump(index)}
              type="button"
              key={index}
              aria-label={`Go to image ${index + 1}`}
            >
              <Dot active={activeItemIndex === index} />
            </ThumbnailLink>
          ))}
        </DotsGrid>
      )}
    </>
  );
}
