import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { tablet, desktop } from 'utils/media';
import theme from 'utils/theme';

import { ArrowLayout, CarouselItemProps } from './types';

export const scrollbarHeight = 5;
export const scrollbarPadding = 20;
export const thumbnailWidth = 90;

const genericScrollbarStyles = (inline: boolean) => `
  ::-webkit-scrollbar {
    height: ${scrollbarHeight}px;
    background-color: rgba(255,255,255,0);
  }

  ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
    border: var(--site-gutter) solid rgba(255,255,255,0);
    border-top-width: 0;
    border-bottom-width: 0;
    background-clip: padding-box;
    
    ${tablet} {
      ${inline ? 'border-left-width: 0; border-right-width: 0;' : ''}
    }
  }
`;

const interactionScrollbarStyles = (
  scrollBarReveal: boolean,
  hideScrollbar: boolean,
) => `
  ${
    hideScrollbar
      ? `
    ::-webkit-scrollbar {
      display: none;
    }
    
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `
      : `
    ::-webkit-scrollbar-track {
      background-color: ${theme.colors.scrollbarBackground};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.darkGreen};
    }

    ${
      scrollBarReveal
        ? `${desktop} {
          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &:hover::-webkit-scrollbar-track {
              background-color: ${theme.colors.scrollbarBackground};
            }
          }`
        : undefined
    }

    ${
      scrollBarReveal
        ? `${desktop} {
            ::-webkit-scrollbar-thumb {
              background-color: transparent;
            }

            &:hover::-webkit-scrollbar-thumb {
              background-color: ${theme.colors.darkGreen};
            }
          }`
        : undefined
    }
  `
  }
`;

interface CarouselRowInnerProps {
  inline?: boolean;
  snap?: boolean;
  scrollBarReveal: boolean;
  hideScrollbar: boolean;
  removeScrollbarGap: boolean;
}

export const CarouselRowInner = styled.div<CarouselRowInnerProps>`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 ${({ hideScrollbar }) => (hideScrollbar ? 0 : 'var(--space-m)')};
  ${({ removeScrollbarGap }) =>
    removeScrollbarGap && 'margin-bottom: calc(-1 * var(--space-m));'}
  overscroll-behavior-x: contain;
  margin-left: var(--carousel-left-gutter, 0);
  margin-right: var(--carousel-right-gutter, 0);
  scroll-snap-type: ${(props) => (props.snap ? 'x mandatory' : 'none')};
  ${(props) => genericScrollbarStyles(props?.inline ?? false)}
  ${(props) =>
    interactionScrollbarStyles(props.scrollBarReveal, props.hideScrollbar)};
  ${({ hideScrollbar }) => hideScrollbar && 'scrollbar-width: none;'}
`;

export const CarouselRowContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

interface ArrowButtonProps {
  arrowLayout: ArrowLayout;
}

function isOverlay(layout: ArrowLayout) {
  return layout.startsWith('overlay');
}

function arrowPosition(layout: ArrowLayout) {
  switch (layout) {
    case 'overlay':
    case 'overlay-tile':
      return 'var(--site-gutter)';
    default:
      return 0;
  }
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  display: none;

  ${desktop} {
    display: flex;
  }

  position: ${(props) =>
    isOverlay(props.arrowLayout) ? 'absolute' : 'static'};
  top: 50%;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;
  color: ${theme.colors.darkGreen};
  width: 37px;
  height: 37px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  ${({ disabled, arrowLayout }) =>
    disabled
      ? arrowLayout === 'top'
        ? `opacity: 0.5;`
        : `visibility: hidden;`
      : ``}

  ${({ arrowLayout }) =>
    isOverlay(arrowLayout)
      ? css`
          transform: translateY(-50%);

          ${arrowLayout === 'overlay-tile' &&
          'top: calc(clamp(150px, 25vw, 290px) / 2);'}

          ${arrowLayout === 'overlay-large' &&
          'top: calc(clamp(150px, 25vw, 290px) / 2); transform: none;'}
        `
      : arrowLayout !== 'top'
      ? `margin-top: 46px;`
      : ''}

  svg {
    width: 29px;
    height: 29px;
  }

  &:first-child {
    left: ${(props) => arrowPosition(props.arrowLayout)};

    svg {
      transform: rotate(90deg);
    }
  }

  &:last-child {
    right: ${(props) => arrowPosition(props.arrowLayout)};

    svg {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    ${({ arrowLayout }) =>
      arrowLayout !== 'top' ? `background: ${theme.colors.white};` : ''}
  }

  &:focus-visible {
    background: ${theme.colors.white};
    outline: none;
    box-shadow: 0 0 0 var(--space-4xs) ${theme.colors.darkGreen};
  }
`;

export const sizeStyles: { [key: string]: FlattenSimpleInterpolation } = {
  tiny: css`
    min-width: 73px;
    width: 73px;
    min-width: clamp(73px, 15vw, 133px);
    width: clamp(73px, 15vw, 133px);
    flex-grow: 0;
  `,
  small: css`
    min-width: 132px;
    max-width: 132px;
  `,
  normal: css`
    min-width: 134px;
  `,
  medium: css`
    width: 165px;
    min-width: 165px;
    flex-grow: 0;
  `,
  large: css`
    min-width: 150px;
    width: 150px;
    min-width: clamp(150px, 25vw, 290px);
    width: clamp(150px, 30vw, 290px);
    flex-grow: 0;
  `,
  xlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 290px;
      min-width: 290px;
    }
  `,
  xxlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 400px;
      min-width: 400px;
    }
  `,
  compost: css`
    min-width: 145px;
    width: 145px;
    min-width: clamp(145px, 35vw, 184px);
    width: clamp(145px, 35vw, 184px);
    flex-grow: 0;
  `,
  articleSmall: css`
    width: 260px;
    min-width: 260px;

    ${tablet} {
      width: 300px;
      min-width: 300px;
    }
  `,
  article: css`
    width: 280px;
    min-width: 280px;

    ${tablet} {
      width: 400px;
      min-width: 400px;
    }
  `,
  collections: css`
    max-height: 540px;
    height: 68vw;
    position: relative;
    padding: 0;
    margin-right: var(--space-xs-s);
    scroll-snap-align: start;

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }

    picture,
    img,
    video {
      max-width: 100vw;
      object-fit: cover;
      height: 100%;
      min-height: 100%;
    }

    /* LQIP positioning */
    picture + picture img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  `,
  shortcut: css`
    min-width: 200px;
  `,
  full: css`
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    flex-shrink: 0;
    padding: 0 var(--space-m);

    ${tablet} {
      padding: 0;
    }
  `,
};

export const CarouselItem = styled.div<CarouselItemProps>`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: 0 var(--space-2xs-xs);
  flex: 1;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
  scroll-snap-stop: always;

  ${({ inline }) =>
    inline
      ? css`
          &:first-child {
            padding-left: var(--space-m);
          }
          &:last-child {
            padding-right: var(--space-m);
          }
          ${tablet} {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        `
      : css`
          &:first-child {
            padding-left: var(--carousel-first-child, var(--site-gutter));
          }
          &:last-child {
            padding-right: var(
              --carousel-last-child,
              var(--carousel-first-child, var(--site-gutter))
            );
          }
        `}

  ${({ size = 'normal' }) => sizeStyles[size]}
`;

export const CarouselItemCaption = styled.figcaption`
  text-align: right;
  color: ${theme.colors.doveGrey};
  font-size: var(--step--1);
  padding-top: var(--space-3xs);
  padding-right: var(--space-3xs);
`;

interface BreakpointProps {
  breakpoint: string;
}

export const DotsGrid = styled.div<BreakpointProps>`
  margin-top: var(--space-m);
  gap: var(--space-xs-s);
  display: none;
  justify-content: center;

  ${({ breakpoint }) => breakpoint} {
    display: flex;
  }
`;

export const ThumbnailsGrid = styled.div<BreakpointProps>`
  margin-top: var(--space-m);
  grid-gap: var(--space-xs-s);
  display: none;
  justify-content: center;
  grid-auto-flow: column;
  grid-auto-columns: ${thumbnailWidth}px;
  overflow-x: auto;
  justify-content: start;
  padding-bottom: var(--space-xs);

  ${({ breakpoint }) => breakpoint} {
    display: grid;
  }

  &:after {
    content: '';
    position: sticky;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      var(--thumbnail-overflow-0, rgba(255, 255, 255, 0)) 0%,
      var(--thumbnail-overflow-1, rgba(255, 255, 255, 0.4)) 30%,
      var(--thumbnail-overflow-2, rgba(255, 255, 255, 1)) 100%
    );
  }
`;

export const ThumbnailLink = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

interface IThumbnailContentProps {
  active: boolean;
}

export const ThumbnailContent = styled.div<IThumbnailContentProps>`
  & > * {
    border: 2px solid transparent;
    transition: border-color 0.1s ease-out;
    ${(props) => props.active && `border-color: ${theme.colors.purple};`}
  }
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  display: inline-block;
  width: var(--space-2xs);
  height: var(--space-2xs);
  padding: 0;
  margin: 0;
  margin-bottom: var(--space-2xs);
  border-radius: 100%;
  background-color: ${({ active }) =>
    active ? theme.colors.darkGreen : theme.colors.scrollbarBackground};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 0 var(--space-2xs);
`;
