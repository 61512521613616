import styled from 'styled-components';

import theme from 'utils/theme';

export const Wrapper = styled.p`
  color: ${theme.colors.grey};
  font-weight: ${theme.weights.bold};
  margin-top: var(--space-2xs);
  margin-bottom: 0;
`;
