import React, { useRef, useState } from 'react';
import { useEvent } from 'react-use';

import { Chevron } from 'components/common/icons';
import { Heading5 } from 'components/common/text';
import { GlobalEvents } from 'utils/global-events';

import {
  Content,
  Summary,
  Details,
  Marker,
  TitleRow,
  SummaryRow,
} from './styles';
import { AccordionProps } from './types';

export default function Accordion({
  dataTestId,
  className,
  id,
  title,
  titleElement = 'h2',
  subtitle,
  children,
  onToggle,
  initiallyOpen = false,
  size = 'normal',
  titleLayout = 'flex',
}: AccordionProps) {
  const [openState] = useState(initiallyOpen);
  const ref = useRef<HTMLDetailsElement>(null);

  useEvent(GlobalEvents.openAccordion, ({ detail }) => {
    if (detail === id && ref.current) {
      ref.current.setAttribute('open', 'open');
    }
  });

  return (
    <Details
      className={className}
      data-testid={dataTestId}
      id={id}
      open={openState}
      onToggle={(event) => {
        // @ts-ignore
        onToggle && onToggle(event.target.open);
      }}
      ref={ref}
    >
      <Summary size={size}>
        <SummaryRow>
          <TitleRow titleLayout={titleLayout}>
            {size === 'small' ? (
              <strong>{title}</strong>
            ) : (
              <Heading5 as={titleElement} inline>
                {title}
              </Heading5>
            )}
            {subtitle ? <span>{subtitle}</span> : null}
          </TitleRow>
          <Marker>
            <Chevron />
          </Marker>
        </SummaryRow>
      </Summary>
      <Content>{children}</Content>
    </Details>
  );
}
