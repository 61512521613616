import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';

import { TickBullet } from 'components/common/icons';
import ImageComponent from 'components/common/image';
import imagekitLoader from 'components/common/image/loaders/imagekit';
import { isBulb } from 'components/layouts/product/helpers';
import { IProductMeta } from 'types/product';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { mobile } from 'utils/media';
import theme from 'utils/theme';

import { ProductTileDebug } from './debug';
import * as St from './styles';
import { IProductTile } from './types';
import { attributeDictionary, getFromPrice, getVariantInfo } from './utils';
import Variants from './variants';

const ProductTile: FC<IProductTile> = ({
  attributes = [],
  className,
  enableQuickBuy = false,
  index = -1,
  onClick,
  onOpenQuickbuyModal,
  product,
  quantity = 1,
  queryId,
  selectable = false,
  selectableBackground = 'white',
  selected = false,
  shouldShowVariants = false,
  showHidePrices = false,
  size = 'tile',
  testId = 'product-grid-item',
}) => {
  const {
    __queryID,
    images,
    centAmount,
    productName,
    sproutlSku,
    variants,
    variantStock,
  } = product;
  const [shouldRenderSecondaryImage, setShouldRenderSecondaryImage] =
    useState<boolean>(false);
  const variantInfo = shouldShowVariants
    ? getVariantInfo(variants, variantStock)
    : null;
  const variantFromPrice =
    shouldShowVariants && variantInfo ? getFromPrice(variantStock) : null;
  let secondaryImage: HTMLImageElement;

  const isInDebugMode = useRouter()?.query?.debug !== undefined;

  const selectableIconSize = size === 'small' || size === 'tile' ? 20 : 25;

  const handleMouseEnter = () => {
    if (!!images?.[1]) {
      secondaryImage = new Image();
      secondaryImage.onload = () => setShouldRenderSecondaryImage(true);
      secondaryImage.src = imagekitLoader({
        src: images[1].url,
        width: 384,
      });
    }
  };

  const handleMouseLeave = () => {
    if (secondaryImage) {
      secondaryImage.onload = null;
    }
    setShouldRenderSecondaryImage(false);
  };

  const handleQuickbuyModalOpen = () =>
    onOpenQuickbuyModal && onOpenQuickbuyModal(product);

  const tileImage = (
    <St.ImageWrapper aspectRatio={1} rounded size={size}>
      <ImageComponent
        objectFit="cover"
        alt={productName}
        aspectRatio={1}
        loader="cloudinary"
        sizes={`${mobile} 150px, 384px`}
        src={shouldRenderSecondaryImage ? images?.[1] : images?.[0]}
      />
    </St.ImageWrapper>
  );

  const tileContent = (
    <St.TileContentWrapper>
      {enableQuickBuy && (
        <St.QuickbuyButton
          onClick={handleQuickbuyModalOpen}
          variant="tertiaryFilled"
          width="full"
        >
          Quick Buy
        </St.QuickbuyButton>
      )}

      <St.Title>{productName}</St.Title>
      {shouldShowVariants && !!variants && (
        <Variants variantInfo={variantInfo} />
      )}
      {attributes.map((key) => {
        const match = attributeDictionary.find(
          (attribute) => attribute.key === key,
        );
        if (!match || !match.key) return null;

        const value = product[match.key as keyof IProductMeta];
        if (!value) return null;

        const isBulbTile = isBulb(product.categorySlug);

        return (
          <St.Subtitle key={key}>
            {match.prefix}{' '}
            {match.key === 'unitsPerPack' && isBulbTile
              ? quantity * value
              : value}{' '}
            {match.suffix}
          </St.Subtitle>
        );
      })}

      {!showHidePrices && (
        <St.Price>
          {shouldShowVariants && variantFromPrice
            ? `From ${formatPrice(variantFromPrice)}`
            : formatPrice(centAmount)}
        </St.Price>
      )}

      {!product.selectedPartner?.stockAmount && (
        <St.OutOfStock>Out of Stock</St.OutOfStock>
      )}

      {isInDebugMode && <ProductTileDebug product={product} />}
    </St.TileContentWrapper>
  );

  if (selectable) {
    return (
      <St.SelectableWrapper
        type="button"
        aria-pressed={selected}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {tileImage}
        {tileContent}

        <St.TickBulletWrapper
          role="presentation"
          selectableBackground={selectableBackground}
          selected={selected}
        >
          <TickBullet
            width={selectableIconSize}
            height={selectableIconSize}
            fill={selected ? theme.colors.irisPurple : theme.colors.lightGrey}
          />
        </St.TickBulletWrapper>
      </St.SelectableWrapper>
    );
  }

  return (
    <St.TileWrapper enableQuickBuy={enableQuickBuy}>
      <Link href={getProductUrl(product)} passHref>
        <St.LinkWrapper
          className={className}
          data-insights-object-id={sproutlSku}
          data-insights-position={index + 1}
          data-insights-query-id={queryId || __queryID}
          data-testid={testId}
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {enableQuickBuy ? (
            tileImage
          ) : (
            <>
              {tileImage}
              {tileContent}
            </>
          )}
        </St.LinkWrapper>
      </Link>

      {enableQuickBuy && tileContent}
    </St.TileWrapper>
  );
};

export default ProductTile;
