import { sortItems } from 'components/search/facets/multiselect/utils';
import { TVariantStock, TVariants } from 'types/product';

import { VARIANT_PROPS } from './constants';
import { IProductTileAttribute } from './types';

export const attributeDictionary: Array<IProductTileAttribute> = [
  {
    key: 'width',
    suffix: 'cm wide',
  },
  {
    key: 'length',
    suffix: 'cm in length',
  },
  {
    key: 'height',
    suffix: 'cm tall',
  },
  {
    key: 'heightCurrent',
    label: 'Current Height',
    suffix: 'cm tall',
  },
  {
    key: 'spreadCurrent',
    label: 'Current Spread',
    suffix: 'cm spread',
  },
  {
    key: 'diameterTop',
    label: 'Diameter',
    suffix: 'cm diameter',
  },
  {
    key: 'volume',
    suffix: ' litres',
  },
  {
    key: 'genusCommon',
  },
  {
    key: 'unitsPerPack',
    suffix: ' bulbs',
  },
];

export function getFromPrice(
  variantStock?: TVariantStock,
  getHighestPrice = false,
) {
  if (!variantStock) {
    return null;
  }

  const variantPrices = Object.values(variantStock)
    .filter(({ centAmount, stockAmount }) => centAmount > 0 && stockAmount > 0)
    ?.sort((a, b) =>
      getHighestPrice
        ? b.centAmount - a.centAmount
        : a.centAmount - b.centAmount,
    );

  return variantPrices.length > 1 ? variantPrices[0]?.centAmount : null;
}

export function getVariantInfo(
  variants?: TVariants,
  variantStock?: TVariantStock,
) {
  if (!variants || !variantStock) {
    return null;
  }

  const { products, variesBy } = variants;
  const variantKeys = Object.keys(VARIANT_PROPS);
  const variantsToShow: {
    [key: string]: number[] | string[];
  } = {};

  variesBy.forEach((variantName) => {
    if (!variantKeys.includes(variantName) || !!variantsToShow[variantName]) {
      return;
    }

    const variantData: Set<any> = new Set();

    products.forEach((product) => {
      const { centAmount = 0, stockAmount = 0 } =
        variantStock[product.sproutlSku] || {};

      if (centAmount > 0 && stockAmount > 0 && !!product[variantName]) {
        variantData.add(product[variantName]);
      }
    });

    variantsToShow[variantName] = [...variantData].sort(sortItems);
  });

  return variantsToShow;
}
