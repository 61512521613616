import { isBulb } from 'components/layouts/product/helpers';

import { AttributesLabelSeparator } from './styles';

export enum MarginBottomOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const verticalRhythmBase = 10;

export const getMarginBottom = (
  configOption: MarginBottomOptions | undefined,
) => {
  switch (configOption) {
    case MarginBottomOptions.Small:
      return `${verticalRhythmBase}px`;
    case MarginBottomOptions.Medium:
      return `${verticalRhythmBase * 2}px`;
    case MarginBottomOptions.Large:
      return `${verticalRhythmBase * 3}px`;
    default:
      return '0px';
  }
};

const variationGroupSlugsReducers: ((
  variationGroup: string,
  category: string,
) => { attribute: string; name: string; suffix?: string } | false)[] = [
  (variationGroup) => {
    return (
      /_diameter_/.test(variationGroup) && {
        attribute: 'diameter_top',
        name: 'Diameter',
        suffix: 'cm',
      }
    );
  },

  (variationGroup) => {
    return (
      /_length_/.test(variationGroup) && {
        attribute: 'length',
        name: 'Length',
        suffix: 'cm',
      }
    );
  },

  (variationGroup) => {
    return (
      /_length_metres_/.test(variationGroup) && {
        attribute: 'length_metres',
        name: 'Length',
        suffix: 'm',
      }
    );
  },

  (variationGroup) => {
    return (
      /_volume_/.test(variationGroup) && {
        attribute: 'volume',
        name: 'Volume',
        suffix: 'l',
      }
    );
  },

  (variationGroup) => {
    return (
      /_current_height_/.test(variationGroup) && {
        attribute: 'height_current',
        name: 'Current Height',
        suffix: 'cm',
      }
    );
  },

  (variationGroup) => {
    return (
      /_weight_/.test(variationGroup) && {
        attribute: 'weight',
        name: 'Weight',
        suffix: 'kg',
      }
    );
  },

  (variationGroup, category) => {
    return (
      /_units_per_pack_/.test(variationGroup) && {
        attribute: 'units_per_pack',
        name: isBulb(category) ? 'Bulbs per Pack' : 'Units per Pack',
      }
    );
  },

  (variationGroup) => {
    return (
      /_colour_/.test(variationGroup) && {
        attribute: 'colour',
        name: 'Colour',
      }
    );
  },
];

function parseProductVariationGroup(
  attributesRaw: { name: string; value: any }[],
) {
  const pickAttributesValue = (name: string) => {
    const { value } =
      attributesRaw.find((attribute) => attribute.name === name) || {};
    return value;
  };

  const variationGroup = pickAttributesValue('variation_group');
  const category: string = pickAttributesValue('category_slug') || '';

  return !variationGroup
    ? null
    : variationGroupSlugsReducers.reduce((acc, reducer) => {
        const {
          attribute,
          name,
          suffix = '',
        } = reducer(variationGroup, category) || {};

        return attribute && name
          ? [
              ...acc,
              {
                attribute,
                name,
                suffix,
                value: pickAttributesValue(attribute),
              },
            ]
          : acc;
      }, [] as { attribute: string; name: string; suffix: string; value: any }[]);
}

export function getAttributesLabel(
  attributesRaw: Parameters<typeof parseProductVariationGroup>[0] | undefined,
) {
  const reduceToLabel = (
    attributes: ReturnType<typeof parseProductVariationGroup>,
  ) => {
    if (!attributes) {
      return null;
    }

    const parseValue = (value: any): string => {
      switch (typeof value) {
        case 'string':
          return value;

        case 'number':
          return String(value);

        case 'object':
          if (Array.isArray(value)) {
            if (value.length === 0) return '';
            if (value.length > 1) return value.join(', ');
            return value[0];
          }
          return value.label || '';

        default:
          return '';
      }
    };

    return attributes.reduce((acc, { name, value, suffix }, index) => {
      const parsedValue = parseValue(value);

      return parsedValue
        ? [
            ...acc,
            <span key={`${name}-${index}`}>
              {`${name}: ${parsedValue} ${suffix}`.trim()}
              {index + 1 < attributes.length && <AttributesLabelSeparator />}
            </span>,
          ]
        : acc;
    }, [] as JSX.Element[]);
  };

  return attributesRaw
    ? reduceToLabel(parseProductVariationGroup(attributesRaw))
    : null;
}
