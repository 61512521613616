import { FC } from 'react';

import { Step } from 'components/common/text';
import { IProductMeta } from 'types/product';

interface IProductTileDebugProps {
  product: IProductMeta;
}

export const ProductTileDebug: FC<IProductTileDebugProps> = ({
  product: { sproutlSku, selectedPartner, unitsPerPack, variationGroupCode },
}) => (
  <Step step={-2} style={{ wordBreak: 'break-all' }}>
    SKU: {sproutlSku}
    {selectedPartner && (
      <>
        <br />
        Partner: {selectedPartner.slug}
        <br />
        Stock: {selectedPartner.stockAmount}
      </>
    )}
    {unitsPerPack && (
      <>
        <br />
        Units per pack: {unitsPerPack}
      </>
    )}
    {variationGroupCode && (
      <>
        <br />
        VGC: {variationGroupCode}
      </>
    )}
  </Step>
);
